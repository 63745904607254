import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import SplitImg from 'assets/images/home/splite_line.png';
import { useConstantToken } from 'hooks/useConstantToken';
import { useGetTokenPrice } from 'hooks/useTokenPrice';
import useSeMetisInfo from 'hooks/useSeMetisInfo';
import Numeral from 'numeral';
import { observer } from 'mobx-react';
import application from 'stores/application';
import useSequencerInfo from 'hooks/useSequencerInfo';
import { formatPoints } from 'utils';
import useEMetisInfo from 'hooks/useEMetisInfo';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 64px;
`;

const SplitLine = styled.span`
    display: block;
    width: 60%;
    border-bottom: 1px dashed ${({ theme }) => theme.c.mainColor};
    margin: 40px 0;
`;

const StakingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 40px 0;
    width: 60%;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 10px;
    flex-direction: column;
    gap: 20px;
    `};
`;

const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;

const SplitImgItem = styled.img`
    width: 60%;
`;

function Overview() {
    const [referralPoints] = useState<number>(0);
    const { metis } = useConstantToken();
    const metisPrice = useGetTokenPrice(metis.address);
    const { totalSupply, getTotalSupply } = useEMetisInfo();
    const totalValueLocked = useMemo(() => {
        return Numeral(totalSupply.multipliedBy(metisPrice || 0)).format(
            '$0.[00]a',
        );
    }, [totalSupply, metisPrice]);
    const { sequencerInfo } = useSequencerInfo();

    useEffect(() => {
        const interval = setInterval(() => {
            application.getStakingPoints();
            getTotalSupply();
        }, 30000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Wrapper>
            <TYPE.main fontSize={16} color="white01" textAlign="center">
                Total Value Locked
            </TYPE.main>

            <TYPE.main
                fontSize={80}
                fontWeight="bold"
                color="mainColor"
                marginTop={12}
                textAlign="center"
                mobileFz={32}
                style={{
                    textTransform: 'uppercase',
                }}
            >
                {totalValueLocked}
            </TYPE.main>

            <TYPE.main
                fontSize={16}
                color="mainColor"
                marginTop={12}
                textAlign="center"
                style={{
                    textTransform: 'uppercase',
                }}
            >
                {Numeral(totalSupply).format(' 0.[00] a')} Metis
            </TYPE.main>

            <SplitLine />

            <TYPE.main fontSize={16} textAlign="center" color="white01">
                Staking Rewards
            </TYPE.main>

            <StakingWrapper>
                <InfoItem>
                    <TYPE.main
                        fontSize={48}
                        fontWeight="bold"
                        marginBottom={12}
                        textAlign="center"
                        color="mainColor"
                        mobileFz={28}
                    >
                        {sequencerInfo?.apr.toFixed(2) || '-'}%
                    </TYPE.main>
                    <TYPE.main
                        fontSize={16}
                        textAlign="center"
                        color="mainColor"
                    >
                        est.APR
                    </TYPE.main>
                </InfoItem>

                <InfoItem>
                    <TYPE.main
                        fontSize={48}
                        fontWeight="bold"
                        marginBottom={12}
                        textAlign="center"
                        color="mainColor"
                        mobileFz={28}
                        style={{
                            textTransform: 'uppercase',
                        }}
                    >
                        {formatPoints(application.stakingPoints) || '-'}
                    </TYPE.main>
                    <TYPE.main
                        fontSize={16}
                        textAlign="center"
                        color="mainColor"
                    >
                        Total Staking Points
                    </TYPE.main>
                </InfoItem>

                <InfoItem>
                    <TYPE.main
                        fontSize={48}
                        fontWeight="bold"
                        marginBottom={12}
                        textAlign="center"
                        color="mainColor"
                        mobileFz={28}
                        style={{
                            textTransform: 'uppercase',
                        }}
                    >
                        {Numeral(application.referrals.points).format('0,0') ||
                            '-'}
                    </TYPE.main>
                    <TYPE.main
                        fontSize={16}
                        textAlign="center"
                        color="mainColor"
                    >
                        Total Referral Points
                    </TYPE.main>
                </InfoItem>
            </StakingWrapper>
            <SplitImgItem src={SplitImg} />
        </Wrapper>
    );
}
export default observer(Overview);
