// eslint-disable-next-line no-use-before-define
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getApolloClient } from './gql';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemedGlobalStyle } from 'theme';
import ThemeProvider from 'theme/ThemeProvider';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';

import { Client } from 'gql/config';
import { BigNumber } from 'bignumber.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import config from 'common-helpers/web3modalconfig';
import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { DefaultChainId } from 'constant';
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN });

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const client = getApolloClient(Client.graph);

root.render(
    <Suspense fallback={<></>}>
        <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
                <RainbowKitProvider
                    initialChain={DefaultChainId}
                    showRecentTransactions={true}
                    appInfo={{
                        appName: 'ENKI Protocol',
                        learnMoreUrl: 'https://docs.enkixyz.com/',
                    }}
                >
                    <ApolloProvider client={client as any}>
                        <StyledEngineProvider injectFirst>
                            <BrowserRouter>
                                <ThemeProvider>
                                    <ThemedGlobalStyle />
                                    <App />
                                </ThemeProvider>
                            </BrowserRouter>
                        </StyledEngineProvider>
                    </ApolloProvider>
                </RainbowKitProvider>
            </QueryClientProvider>
        </WagmiProvider>
    </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
