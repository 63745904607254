import { getCurrentChainId } from 'gql';
import { ChainId, DefaultChainId, RPC } from '../constant';
import { action, autorun, makeAutoObservable } from 'mobx';
import Web3 from 'web3';
import {
    getStakingPoints,
    getReferralsPoints,
    InitReferrals,
    ReferralPoints,
} from 'gql/points';

export class User {
    isOpenConnectModal: boolean = false;
    isDark: boolean = true;
    web3: any;
    address: string = '';
    chainId: ChainId = getCurrentChainId();
    connected: boolean = false;
    stakingPoints: number = 0;
    referrals: ReferralPoints = InitReferrals;
    constructor() {
        makeAutoObservable<this>(this);
        autorun(() => {
            if (this.address) {
                this.getStakingPoints(this.address);
                this.getReferrals(this.address);
            } else {
                this.setStakingPoints(0);
                this.setReferrals(InitReferrals);
            }
        });
    }

    toggleConnectModal = () => {
        this.isOpenConnectModal = !this.isOpenConnectModal;
    };

    toggleTheme = () => {
        this.isDark = !this.isDark;
    };

    setWeb3 = (web3: any) => {
        this.web3 = web3;
    };

    setAddress = (address: string) => {
        this.address = address;
    };

    setConnected = (connected: boolean) => {
        this.connected = connected;
    };
    setChainId = (chainId: ChainId) => {
        this.chainId = chainId;
    };

    setStakingPoints = (points: number) => {
        this.stakingPoints = points;
    };

    setReferrals = (res: ReferralPoints) => {
        this.referrals = res;
    };

    @action getStakingPoints = async (address: string) => {
        try {
            const points = await getStakingPoints(address);
            this.setStakingPoints(points);
        } catch (e) {
            console.error('getStakingPoints error');
        }
    };

    @action getReferrals = async (address: string) => {
        try {
            const res = await getReferralsPoints(address);
            this.setReferrals(res);
        } catch (e) {
            console.error('getReferrals error');
        }
    };

    clearLoginInfo = () => {
        this.web3 = new Web3(
            new Web3.providers.HttpProvider(RPC[DefaultChainId]),
        );
        this.chainId = DefaultChainId;
        this.address = '';
        this.connected = false;
        this.stakingPoints = 0;
        this.referrals = InitReferrals;
    };
}

export default new User();
