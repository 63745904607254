import { gql } from '@apollo/client';
import { getApolloClient } from '../index';
import { Client } from '../config';

export interface Distributor {
    merkleRoot: string;
    nftAddress: string;
    nftName: string;
    nftSymbol: string;
    tokenAddress: string;
    distributorAddress: string;
}

export function getDistributors(): Promise<Distributor[] | undefined> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.distributor)
            .query<{
                distributors: Distributor[];
            }>({
                query: gql`
                    query distributors {
                        distributors {
                            merkleRoot
                            nftAddress
                            nftName
                            nftSymbol
                            tokenAddress
                            distributorAddress
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res: any) => {
                const { data } = res;
                resolve(data?.distributors);
            })
            .catch((e: any) => {
                resolve(undefined);
                console.error(e);
            });
    });
}

export interface MerkleLeaf {
    amount: string;
    index: number;
    proof: string[];
}

export function getMerkleLeaf(
    user: string,
    nftName: string,
): Promise<MerkleLeaf | undefined> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.distributor)
            .query<{
                merkleLeaf: MerkleLeaf;
            }>({
                query: gql`
                    query merkleLeaf($address: String!, $tree: String!) {
                        merkleLeaf(address: $address, tree: $tree) {
                            amount
                            index
                            proof
                        }
                    }
                `,
                variables: {
                    tree: nftName,
                    address: user,
                },
                fetchPolicy: 'no-cache',
            })
            .then((res: any) => {
                const { data } = res;
                if (data?.merkleLeaf?.index >= 0) {
                    resolve(data?.merkleLeaf);
                } else {
                    resolve(undefined);
                }
            })
            .catch((e: any) => {
                resolve(undefined);
                console.error(e);
            });
    });
}
