import { EnkiSymbolSVG } from 'common-helpers/img';

import { AutoColumn } from 'components/Column';
import { RowBetween } from 'components/Row';
import ConnectWalletButton from 'pages/components/ConnectWalletButton';
import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { TYPE } from 'theme';

import Stepper from './components/stepper';

import useGetDistributor from 'hooks/useGetDistributor';
import user from 'stores/user';
import { observer } from 'mobx-react-lite';
import { CardInfo } from './type';
import DistributorCard from './components/distributorCard';
import PreStaking from './components/preStaking';

const GlobalStyle = createGlobalStyle`
    .mainView{
        padding: 0 !important;
    }
`;

const TopWrapper = styled.div`
    height: 768px;
    background: url(${require('./img/top-bg.png')}) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    height: fit-content;
    `};
`;

const TopBannerInner = styled.div`
    padding-top: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;

    .enkiBanner {
        width: 318px;
        height: 318px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 60px;
        height: 60px;
        position: absolute;
        right: 20px;
        top: 40%;
        display: none;
        `};
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
     padding-top: 80px;
     position:relative;
    `};
`;

const TopBannerLeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 750px;
    padding: 64px 80px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 0 20px;
    background: unset;
    `};

    .titleWrapper {
        gap: 40px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 16px;
        justify-content: center;
        `};
    }

    .enkiLogo {
        width: 120px;
        height: 120px;
        flex-shrink: 0;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 50px;
        height: 50px;
        `};
    }

    .title {
        background: linear-gradient(180deg, #be692a 0%, #e8c074 100%);
        font-size: 58px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
        word-wrap: break-word;
        width: calc(100% - 148px);

        ${({ theme }) => theme.mediaWidth.upToSmall`
        font-size: 28px;
        `};
    }
`;

const Desc = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background: linear-gradient(
        270deg,
        rgba(223, 60, 21, 0) 0%,
        #7b0d02 48%,
        rgba(29, 6, 2, 0) 100%
    );
    box-sizing: border-box;
    padding: 16px 32px;
    color: #fff;
    font-size: 16px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    position: relative;
    transform: unset;
    bottom: unset;
    left: unset;
    `};
`;

const TabWrapper = styled.div`
    display: flex;
    gap: 32px;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 8px;
    margin-top: 20px;
    width: 100%;
    `};
`;

const TabButton = styled.div`
    cursor: pointer;
    height: 64px;
    border-radius: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    min-width: 200px;
    background-color: transparent;
    border: 1px solid #be692a;
    border-radius: 42px;
    color: #be692a;
    &.active {
        background: linear-gradient(270deg, #be692a 0%, #e8c074 100%);
        color: #000;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 30%;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: unset;
    height: 48px;
    `};
`;

const Wrapper = styled.div`
    background: linear-gradient(180deg, rgba(21, 7, 1, 0) 0%, #150701 100%);
`;

const Content = styled.div`
    background: #150701;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 0;
    width: 100%;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 32px 0;
    `};
`;

const InnerContent = styled.div`
    max-width: 900px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.hide {
        display: none;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 20px;
    `};

    .phase1Title {
        gap: 24px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 8px;
        
        `};
    }
`;

const BottonBannerWrapper = styled.div`
    background: url(${require('./img/botton-banner.png')}) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 373px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 200px;
    `};
`;

const JoinButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 22px;
    height: 88px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 44px;
    border: 1px solid #dca45c;
    font-size: 32px;
    gap: 16px;
    min-width: 250px;
    color: #dca45c;
    cursor: pointer;
    img {
        width: 40px;
        height: 40px;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fox-content;
    height: 60px !important;
    font-size: 24px !important;
    `};
`;

const ConnectButton = styled(ConnectWalletButton)`
    background-color: transparent !important;
    border-radius: 50px !important;
    border: 1px solid #dca45c !important;
    height: 100px !important;
    color: #dba45c !important;
    padding: 28px 40px !important;
    font-size: 32px !important;
    text-transform: uppercase;
    margin-top: 32px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 24px !important;
    height: auto !important;
    padding: 16px 24px !important;
    `};
`;

const CardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    margin-top: 80px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    margin-top: 20px;
    `};
`;

const ComingSoon = styled.div`
    padding: 80px 0 40px 0;
    font-family: Poppins, Poppins;
    font-weight: 400;
    font-size: 40px;
    color: #dca45c;
    line-height: 60px;
    text-align: right;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 40px 0
    `};
`;

const Tips = styled.div`
    height: 56px;
    background: rgba(219, 164, 92, 0.1);
    border-radius: 32px;
    border: 1px dashed #dba45c;
    color: #dba45c;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    margin-top: 40px;
    text-align: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    width: calc(100% - 20px)
    `};
`;

const NewsImg = styled.img`
    width: 900px;
    height: auto;
    margin: 0 auto;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const CardConfig: CardInfo[] = [
    {
        title: '01 Community Participation and Trivia Tasks',
        desc: 'Sharing 25,000 ENKI',
        infoTitle: 'Explorer',
        detail: [
            {
                title: '10000',
                desc: 'NFTs Claimed',
            },
            {
                title: 'ERC-721',
                desc: 'Standard',
            },
            {
                title: 'Andromeda',
                desc: 'Network',
            },
        ],
    },
    {
        title: '02 Testnet Participation',
        desc: 'Sharing 25,000 ENKI',
        infoTitle: 'Pioneer',
        detail: [
            {
                title: '10000',
                desc: 'NFTs Claimed',
            },
            {
                title: 'ERC-721',
                desc: 'Standard',
            },
            {
                title: 'Andromeda',
                desc: 'Network',
            },
        ],
    },
    {
        title: '03 Testnet Bug Bounty and Marketing Collaborations',
        desc: 'Sharing 100,000 ENKI',
        infoTitle: 'Guarantor',
        detail: [
            {
                title: '10000',
                desc: 'NFTs Claimed',
            },
            {
                title: 'ERC-721',
                desc: 'Standard',
            },
            {
                title: 'Andromeda',
                desc: 'Network',
            },
        ],
    },
];

function Fantasy() {
    const [tab, setTab] = useState('intro');

    const { distributors } = useGetDistributor();

    return (
        <Wrapper>
            <GlobalStyle />
            <TopWrapper>
                <TopBannerInner>
                    <TopBannerLeftTextWrapper>
                        <RowBetween align="center" className="titleWrapper">
                            <EnkiSymbolSVG className="enkiLogo" />
                            <TYPE.main className="title">
                                Fantasy is now live.
                            </TYPE.main>
                        </RowBetween>
                        <TYPE.main fontSize={16} color="rgba(229, 185, 110, 1)">
                            Fantasy is the genesis plan for ENKI tokens, for
                            comprehensive details, refer to the{' '}
                            <a
                                href="https://medium.com/@ENKIProtocol/the-enki-fantasy-genesis-plan-b83d24cdc39a"
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                    color: '#1a84d8',
                                }}
                            >
                                article
                            </a>{' '}
                            on Medium.
                        </TYPE.main>
                        <Desc>
                            1 million(10% of the total supply) ENKI tokens
                            allocated to Fantasy.
                        </Desc>
                    </TopBannerLeftTextWrapper>
                    <img
                        src={require('./img/enki-banner.png')}
                        className="enkiBanner"
                    />
                </TopBannerInner>
                <Tips>
                    The Fantasy page is estimated to conclude on January 1,
                    2025.
                </Tips>
                <TabWrapper>
                    <TabButton
                        className={tab === 'intro' ? 'active' : ''}
                        onClick={() => {
                            setTab('intro');
                        }}
                    >
                        Intro
                    </TabButton>
                    <TabButton
                        className={tab === 'phase1' ? 'active' : ''}
                        onClick={() => {
                            setTab('phase1');
                        }}
                    >
                        Phase 1
                    </TabButton>
                    <TabButton
                        className={tab === 'phase2' ? 'active' : ''}
                        onClick={() => {
                            setTab('phase2');
                        }}
                    >
                        Phase 2
                    </TabButton>
                </TabWrapper>
            </TopWrapper>

            <Content>
                <InnerContent className={tab !== 'intro' ? 'hide' : ''}>
                    <AutoColumn gap="40px" style={{ padding: '10px' }}>
                        <NewsImg src={require('./img/news.png')} />
                        <TYPE.main fontSize={16} color="#fff">
                            {`Fantasy presents an exclusive and unparalleled
                            opportunity to engage deeply with the ENKI ecosystem
                            ahead of its official launch on the mainnet. The
                            ENKI token transcends the typical digital asset,
                            serving as a pivotal element within the staking
                            process by offering a 'reward booster' that grants
                            users an additional 30% in sequencer rewards.
                            `}
                        </TYPE.main>
                        <TYPE.main fontSize={16} color="#fff">
                            {`Understanding the critical role of the ENKI token, we're
                        meticulously planning its distribution to align with our
                        ecosystem's long-term health and expansion. Therefore,
                        engaging in "Fantasy" offers the unique chance to obtain
                        ENKI tokens before the protocol's debut on the mainnet.
                            `}
                        </TYPE.main>

                        <TYPE.main fontSize={16} color="rgba(229, 185, 110, 1)">
                            Phase One - 150,000 ENKI tokens
                            allocated(Pre-launch)
                        </TYPE.main>
                        <TYPE.main fontSize={16} color="#fff">
                            {`Phase 1 sets the stage for unveiling the ENKI
                            protocol's narrative and engaging the community
                            through early marketing efforts and testnet
                            participation.`}
                        </TYPE.main>
                        <TYPE.main fontSize={16} color="rgba(229, 185, 110, 1)">
                            Phase Two - 850,000 ENKI tokens
                            allocated(Post-launch)
                        </TYPE.main>
                        <TYPE.main fontSize={16} color="#fff">
                            {`Phase 2 is designed to boost user engagement with
                            Metis staking and support the protocol's expansion.`}
                        </TYPE.main>
                    </AutoColumn>
                </InnerContent>
                <InnerContent className={tab !== 'phase1' ? 'hide' : ''}>
                    <AutoColumn justify="center" className="phase1Title">
                        <TYPE.main
                            color="rgba(220, 164, 92, 1)"
                            fontSize={64}
                            fontWeight={600}
                            mobileFz={48}
                        >
                            150,000 ENKI
                        </TYPE.main>{' '}
                        <ConnectButton />
                        {!user.address && (
                            <TYPE.main color="#fff" textAlign="center">
                                Connect your wallet to confirm your eligibility
                            </TYPE.main>
                        )}
                    </AutoColumn>
                    <CardWrapper>
                        {CardConfig.map((item, index) => (
                            <DistributorCard
                                key={index}
                                config={item}
                                index={index}
                                distributor={
                                    distributors
                                        ? distributors[index]
                                        : undefined
                                }
                            />
                        ))}
                    </CardWrapper>
                </InnerContent>
                <InnerContent className={tab !== 'phase2' ? 'hide' : ''}>
                    <AutoColumn justify="center" gap="24px">
                        <TYPE.main
                            color="rgba(220, 164, 92, 1)"
                            fontSize={64}
                            fontWeight={600}
                            mobileFz={48}
                        >
                            850,000 ENKI
                        </TYPE.main>
                    </AutoColumn>
                    {/* <Stepper /> */}
                    {/* <ComingSoon>Coming Soon</ComingSoon> */}
                    <PreStaking />
                    <TYPE.main
                        fontSize={32}
                        fontWeight={600}
                        color="rgba(220, 164, 92, 1)"
                        textAlign={'center'}
                        marginTop={40}
                    >
                        More activities are coming soon...
                    </TYPE.main>
                </InnerContent>
            </Content>
            <BottonBannerWrapper>
                <JoinButton
                    onClick={() => {
                        window.open('https://t.me/enkixyz');
                    }}
                >
                    <img src={require('assets/images/media/ic_telegram.png')} />
                    <span>JOIN</span>
                </JoinButton>
                <TYPE.main
                    fontSize={16}
                    color="rgba(229, 185, 110, 1)"
                    marginTop="20px"
                    onClick={() => {
                        window.open('https://t.me/enkixyz');
                    }}
                >
                    Click to join ENKI Telegram group
                </TYPE.main>
            </BottonBannerWrapper>
        </Wrapper>
    );
}

export default observer(Fantasy);
