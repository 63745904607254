/* eslint-disable react/jsx-key */
import {
    ContentTitle,
    DataColumn,
    DataDesc,
    DataLink,
    DataTitle,
} from 'components/Page/DataComponents';
import DataWrapper from 'components/Page/DataWrapper';
import DropMenu from 'components/Page/DropMenu';
import PageWrapper from 'components/Page/PageWrapper';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

import { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';

import BigNumber from 'bignumber.js';
import TokenInput from 'components/TokenInput/SwitchTokenInput';

import { useConstantToken } from 'hooks/useConstantToken';
import { useEMetisMinter } from 'hooks/useContract';
import useSend from 'hooks/useSend';
import useTokenApprove from 'hooks/useTokenApprove';
import user from 'stores/user';

import useTokenBalance from 'hooks/useTokenBalance';
import { useGetEstimatedGas } from 'hooks/useEstimateGas';
import useEMetisInfo from 'hooks/useEMetisInfo';
import Numeral from 'numeral';
import { useGetEMetisMetisLPConfig } from 'hooks/useGetResources';
import useExploreLink from 'hooks/useExploreLink';

import PageContent, {
    ButtonGroup,
    ButtonRow,
    FullLineButton,
    LeftContent,
    RightContent,
    RightContentTitle,
    TransactionDetail,
    TransactionText,
    TransactionTokenLogo,
} from 'components/Page/PageContent';
import { observer } from 'mobx-react';
import { formatNumber, formatPoints } from 'utils';
import { useGetInviterAddress } from 'hooks/useGetInviterAddress';
import PreStakingTooltips from 'components/Page/PreStakingTooltips';
import TooltipInfo from 'components/TooltipInfo';
import application from 'stores/application';
import { isMobile } from 'react-device-detect';

function Mint() {
    const [inputValue, setInputValue] = useState(new BigNumber(0));

    const { metis, eMetis } = useConstantToken();
    const eMetisMinterContract = useEMetisMinter();
    const { getAddressUrl } = useExploreLink();
    const { totalSupply: eMetisTotalSupply } = useEMetisInfo();
    const { lps } = useGetEMetisMetisLPConfig();
    const { balance: metisBalance, getBalance: getMetisBalance } =
        useTokenBalance(metis.address, user.address);
    const { balance: eMetisBalance, getBalance: getEMetisBalance } =
        useTokenBalance(eMetis.address, user.address);
    const { approved: approvedEMetisMinter, approve: approveEMetisMinter } =
        useTokenApprove(metis, eMetisMinterContract?.address || '', inputValue);
    const inviterAddress = useGetInviterAddress();

    const send = useSend();

    const [inputValid, setInputValid] = useState(false);

    const canClickButton = useMemo(() => {
        return inputValue.isGreaterThan(0) && inputValid;
    }, [inputValue, inputValid]);

    function onTransSuccess() {
        setInputValue(new BigNumber(0));
        getMetisBalance();
        getEMetisBalance();
    }

    const estimatedGas = useGetEstimatedGas({
        contract: eMetisMinterContract,
        method: 'mint',
        params: [
            user.address,
            inputValue.shiftedBy(metis.decimals).toFixed(0),
            false,
            inviterAddress,
        ],
        show: inputValue.isGreaterThan(0),
        fetch: approvedEMetisMinter && inputValue.isGreaterThan(0),
    });

    async function mint() {
        if (!eMetisMinterContract) return;

        await send({
            contract: eMetisMinterContract,
            method: 'mint',
            params: [
                user.address,
                inputValue.shiftedBy(metis.decimals).toFixed(0),
                false,
                inviterAddress,
            ],
            pendingText: `Minting ${inputValue.toFixed()} eMetis`,
            summary: `Mint ${inputValue.toFixed()} eMetis`,
            onSuccess: onTransSuccess,
        });
    }

    async function mintAndStake() {
        if (!eMetisMinterContract) return;

        await send({
            contract: eMetisMinterContract,
            method: 'mint',
            params: [
                user.address,
                inputValue.shiftedBy(metis.decimals).toFixed(0),
                true,
                inviterAddress,
            ],
            pendingText: `Minting and Staking ${inputValue.toFixed()} eMetis`,
            summary: `Mint and Stake ${inputValue.toFixed()} eMetis`,
            onSuccess: onTransSuccess,
        });
    }

    const lpItemLink: React.ReactNode[] = useMemo(() => {
        return lps?.length
            ? lps.map((item, index) => (
                  <DataLink href={item.website} key={index}>
                      <DataDesc>{item.name}</DataDesc>
                  </DataLink>
              ))
            : [
                  <DataLink href="#" showLinkIcon={false}>
                      <DataDesc>Coming Soon</DataDesc>
                  </DataLink>,
              ];
    }, [lps]);
    return (
        <PageWrapper>
            <DataWrapper>
                <DataColumn>
                    <DataTitle>
                        {Numeral(eMetisTotalSupply).format('0,0.[0000]')} eMetis
                    </DataTitle>
                    <DataDesc>TOTAL MINTED AMOUNT</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataLink href="https://sequencer.metis.io/#/sequencers/0x36f10b20781bb1e78278e84f3e1e97acf92fa302">
                        <DataTitle>NODE INFO </DataTitle>
                    </DataLink>
                </DataColumn>
                <DataColumn>
                    <DropMenu dropItems={lpItemLink}>
                        <DataTitle>DEX LP</DataTitle>
                    </DropMenu>
                    <DataDesc>eMetis Price: 1 Metis</DataDesc>
                </DataColumn>
                <DataColumn leftBorder={!isMobile}>
                    <DataTitle>CONTRACTS</DataTitle>
                    <DataLink href={getAddressUrl(eMetis.address)}>
                        <DataDesc>eMetis</DataDesc>
                    </DataLink>
                    <DataLink
                        href={getAddressUrl(
                            eMetisMinterContract?.address || '',
                        )}
                    >
                        <DataDesc>eMetis Minter</DataDesc>
                    </DataLink>
                </DataColumn>
            </DataWrapper>
            <ContentTitle>
                <PreStakingTooltips>Mint eMetis</PreStakingTooltips>
            </ContentTitle>
            {isMobile && application.preStaking && (
                <DataDesc>
                    Earn double staking points at pre-staking stage.
                </DataDesc>
            )}

            <PageContent>
                <LeftContent>
                    <TokenInput
                        value={inputValue}
                        onChange={setInputValue}
                        inputPercent={[50]}
                        token={metis}
                        dToken={eMetis}
                        onInputValid={setInputValid}
                        exchangeRate={1}
                    />
                    <RowBetween>
                        <TYPE.main fontSize={14}>SWAP FEE | 0.00%</TYPE.main>
                        <TYPE.main fontSize={14}>(0.00 Metis)</TYPE.main>
                    </RowBetween>
                    <TransactionDetail>
                        <RowBetween>
                            <TransactionText>MIN. RECEIVED</TransactionText>

                            <TransactionText>
                                <TransactionTokenLogo src={eMetis.img} />
                                {inputValue.toFixed()} eMetis
                            </TransactionText>
                        </RowBetween>
                    </TransactionDetail>
                    <ButtonGroup>
                        {(!approvedEMetisMinter || !user.connected) && (
                            <FullLineButton
                                auth
                                style={{ width: '100% !important' }}
                                disabled={!canClickButton}
                                onClick={approveEMetisMinter}
                            >
                                Approve First
                            </FullLineButton>
                        )}

                        {user.connected && approvedEMetisMinter && (
                            <ButtonRow>
                                {!application.preStaking && (
                                    <Button
                                        auth
                                        disabled={!canClickButton}
                                        onClick={mintAndStake}
                                    >
                                        Mint & Stake
                                    </Button>
                                )}
                                <Button
                                    onClick={mint}
                                    disabled={!canClickButton}
                                    type="outline"
                                >
                                    Mint Only
                                </Button>
                            </ButtonRow>
                        )}
                    </ButtonGroup>
                </LeftContent>
                <RightContent>
                    <AutoColumn gap="48px">
                        <RightContentTitle>Current Stake</RightContentTitle>
                        <AutoColumn gap="32px">
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(metisBalance)} Metis
                                </DataTitle>
                                <DataDesc>Your Metis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(eMetisBalance)} eMetis
                                </DataTitle>
                                <DataDesc>Your eMetis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                {application.preStaking ? (
                                    <TooltipInfo
                                        text={'2X'}
                                        background="redTooltips"
                                    >
                                        <DataTitle>
                                            {formatPoints(user.stakingPoints)}
                                        </DataTitle>
                                    </TooltipInfo>
                                ) : (
                                    <DataTitle>
                                        {formatPoints(user.stakingPoints)}
                                    </DataTitle>
                                )}
                                <DataDesc>Your Staking Point</DataDesc>
                            </DataColumn>
                            <DataLink href="https://docs.enkixyz.com/guides/mint">
                                <DataDesc>Documentation</DataDesc>
                            </DataLink>
                        </AutoColumn>
                    </AutoColumn>
                </RightContent>
            </PageContent>
        </PageWrapper>
    );
}
export default observer(Mint);
