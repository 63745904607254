/* eslint-disable react/jsx-key */
import {
    ContentTitle,
    DataColumn,
    DataDesc,
    DataLink,
    DataTitle,
} from 'components/Page/DataComponents';
import DataWrapper from 'components/Page/DataWrapper';

import PageWrapper from 'components/Page/PageWrapper';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

import { RowBetween } from 'components/Row';
import Button from 'components/Button';
import { AutoColumn } from 'components/Column';

import BigNumber from 'bignumber.js';
import TokenInput from 'components/TokenInput/SwitchTokenInput';

import { useConstantToken } from 'hooks/useConstantToken';

import user from 'stores/user';

import useTokenBalance from 'hooks/useTokenBalance';

import PageContent, {
    ButtonGroup,
    ComingSoonImg,
    ComingSoonWrapper,
    LeftContent,
    RightContent,
    RightContentTitle,
    TransactionDetail,
    TransactionText,
    TransactionTokenLogo,
} from 'components/Page/PageContent';
import { observer } from 'mobx-react';
import { formatNumber } from 'utils';

function Redeem() {
    const [inputValue, setInputValue] = useState(new BigNumber(0));

    const { metis, eMetis } = useConstantToken();

    const { balance: metisBalance, getBalance: getMetisBalance } =
        useTokenBalance(metis.address, user.address);
    const { balance: eMetisBalance, getBalance: getEMetisBalance } =
        useTokenBalance(eMetis.address, user.address);

    const [inputValid, setInputValid] = useState(false);

    return (
        <PageWrapper>
            <ComingSoonWrapper>
                <ComingSoonImg
                    src={require('assets/images/ic_comingsoon.png')}
                />
            </ComingSoonWrapper>

            <DataWrapper>
                <DataColumn>
                    <DataTitle>- DAYS</DataTitle>
                    <DataDesc>WAITING PERIOD</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>- %</DataTitle>
                    <DataDesc>EARLY EXIT FEES</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>- %</DataTitle>
                    <DataDesc>REDEMPTION FEES</DataDesc>
                </DataColumn>
                <DataColumn leftBorder>
                    <DataTitle>- </DataTitle>
                    <DataDesc>CONTRACTS</DataDesc>
                </DataColumn>
            </DataWrapper>
            <ContentTitle>Enter Redemption Queue</ContentTitle>
            <PageContent>
                <LeftContent>
                    <TokenInput
                        value={inputValue}
                        onChange={setInputValue}
                        inputPercent={[50]}
                        token={eMetis}
                        onInputValid={setInputValid}
                    />
                    <AutoColumn gap="16px" style={{ width: '100%' }}>
                        <RowBetween>
                            <TYPE.main fontSize={14}>
                                SWAP FEE | 0.00%
                            </TYPE.main>
                            <TYPE.main fontSize={14}>(0.00 Metis)</TYPE.main>
                        </RowBetween>
                        <RowBetween>
                            <TYPE.main fontSize={14}>REDEEMABLE DATE</TYPE.main>
                            <TYPE.main fontSize={14}>--</TYPE.main>
                        </RowBetween>
                    </AutoColumn>
                    <TransactionDetail>
                        <RowBetween>
                            <TransactionText>YOU RECEIVE</TransactionText>

                            <TransactionText>
                                <TransactionTokenLogo src={metis.img} />
                                0.0000 Metis
                            </TransactionText>
                        </RowBetween>
                    </TransactionDetail>
                    <ButtonGroup>
                        <Button type="outline">Enter Queue</Button>
                    </ButtonGroup>
                </LeftContent>
                <RightContent>
                    <AutoColumn gap="48px">
                        <RightContentTitle>Current Stake</RightContentTitle>
                        <AutoColumn gap="32px">
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(metisBalance)} Metis
                                </DataTitle>
                                <DataDesc>Your Metis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(eMetisBalance)} eMetis
                                </DataTitle>
                                <DataDesc>Your eMetis Balance</DataDesc>
                            </DataColumn>
                            <DataLink href="https://docs.enkixyz.com/guides/redeem">
                                <DataDesc>Documentation</DataDesc>
                            </DataLink>
                        </AutoColumn>
                    </AutoColumn>
                </RightContent>
            </PageContent>
        </PageWrapper>
    );
}
export default observer(Redeem);
