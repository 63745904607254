import { useSeMetisVault } from './useContract';
import { useEffect, useMemo, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useConstantToken } from './useConstantToken';
import user from 'stores/user';
import Axios from 'axios';
import { token } from 'gql/token';

export default function useSeMetisInfo() {
    const { seMetis, eMetis } = useConstantToken();
    const { chainId } = user;
    const seMetisContract = useSeMetisVault();
    const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
    const [tokenHolder, setTokenHolder] = useState(0);

    const [exchangeRate, setExchangeRate] = useState(0);

    async function getTotalSupply() {
        if (!seMetisContract) return;

        try {
            const res = await seMetisContract.totalSupply();

            if (res) {
                setTotalSupply(
                    new BigNumber(res?.toString()).shiftedBy(-seMetis.decimals),
                );
            }
        } catch (e) {
            console.error('get seMetis totalSupply error');
            console.error(e);
        }
    }

    async function previewDeposit() {
        if (!seMetisContract) return;
        try {
            const res = await seMetisContract.previewRedeem(10000);

            if (res) {
                setExchangeRate(10000 / res);
            }
        } catch (e) {
            console.log('get seMetis previewDeposit error');
            console.error(e);
        }
    }

    async function getTokenHolder() {
        try {
            if (!seMetisContract?.address) return;
            const res = await token('seMetis');
            if (res?.holders) {
                setTokenHolder(res?.holders);
            }
        } catch (e) {
            console.error('get token holder error');
            console.error(e);
        }
    }

    useEffect(() => {
        getTotalSupply();

        previewDeposit();
    }, [seMetisContract]);

    useEffect(() => {
        getTokenHolder();
    }, [chainId, seMetisContract]);

    const totalStakedMetis = useMemo(() => {
        if (exchangeRate > 0 && totalSupply.isGreaterThan(0)) {
            return totalSupply.dividedBy(exchangeRate).toNumber();
        }

        return 0;
    }, [exchangeRate, totalSupply]);

    return {
        totalSupply,
        tokenHolder,
        exchangeRate,
        totalStakedMetis,
    };
}
