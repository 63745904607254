import React, { useEffect, useMemo, useState } from 'react';

import Row from 'components/Row';

import Button from 'components/Button';
import BigNumber from 'bignumber.js';

import { Token } from '../../constant';

import styled from 'styled-components';
import { TYPE } from 'theme';
import { formatNumber } from 'utils';

import useTokenBalance from 'hooks/useTokenBalance';

import { observer } from 'mobx-react-lite';
import { AutoColumn } from 'components/Column';

import { useConstantToken } from 'hooks/useConstantToken';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 32px;
    background: transparent;
    width: 100%;
    box-sizing: border-box;
    padding: 30px;
    background: linear-gradient(90deg, #2c160a 0%, #150701 100%);

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    gap: 10px;
    `};

    .tokenWrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        background: #150701;
        border-radius: 32px;
        width: 188px;
        height: 80px;
        flex-shrink: 0;
        padding: 0 20px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
         padding: 0 10px;
         height: 60px;
         width: fit-content;
        `};
        img {
            width: 36px;
            height: 36px;
            ${({ theme }) => theme.mediaWidth.upToSmall`
            width: 30px;
            height: 30px;
            `};
        }
    }

    .input {
        background: transparent;
        flex-grow: 1;
        width: 100%;
        height: 30px;
        margin-left: 20px;
        outline: none;
        border: none;
        text-align: right;
        color: ${({ theme }) => theme.c.t01};
        font-size: 36px;
        font-weight: bold;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
         font-size: 30px;
        `};
        &::placeholder {
            color: ${({ theme }) => theme.c.t02};
            ${({ theme }) => theme.mediaWidth.upToSmall`
            font-size: 30px;
            `};
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
     .line {
          flex-wrap: wrap;
      }

      .input {
          min-width: 20%;
          flex-shrink: 1;
          height: 100%;
      }

    `}
`;

const MaxButton = styled(Button)`
    height: 27px !important;
    background: linear-gradient(270deg, #be692a 0%, #e8c074 100%), #d8d8d8 !important;

    font-size: 18px !important;
    font-weight: 500 !important;
    border-radius: 15px !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    min-width: unset !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
          padding: 10px 15px !important;
    `};
`;

const InputRow = styled(Row)`
    width: 100%;
    height: 100px;
    align-items: center;
    box-sizing: border-box;
    font-size: 18px !important;
    font-weight: bold !important;
    border-radius: 24px;
    background: transparent;
    justify-content: space-between;
    gap: 20px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 75px;
    padding: 8px 16px;
    margin-top: 8px;
    `}/* border-bottom: 1px solid rgba(7, 221, 208, 0.2); */
`;

const PartButton = styled(Button)`
    height: 48px !important;
    color: #000 !important ;
    font-size: 18px !important;
    font-weight: 600;
    border-radius: 15px !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px !important;
    margin-right: 10px;
    border: 1px solid #e8dbd8 !important;

    ${({ theme }) => theme.mediaWidth.upToSmall`
          padding: 10px 15px !important;
    `};
`;

const StyledLine = styled.div`
    position: relative;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const Line = styled.div`
    height: 2px;
    border-top: 1px dashed #6b4a28;
    flex-grow: 1;
`;

const StyledArow = styled.img`
    flex-shrink: 0;
    flex-grow: 0;
    width: 48px;
    height: 48px;
    cursor: pointer;
    &.disabled {
        cursor: not-allowed;
    }
`;

const StyledWarning = styled(ErrorOutlineIcon)`
    color: #dca45c;
    width: 18px;
    height: 18px;
    margin-right: 8px;
`;

const StyledColumn = styled(AutoColumn)`
    gap: 16px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 4px
    `};
`;

interface Props {
    value: BigNumber;
    onChange: (value: BigNumber) => void;
    disabled?: boolean;
    onInputValid?: (value: boolean) => void;
    token: Token;
    dToken?: Token;
    inputPercent?: number[];
    bindUpdateMethod?(method: () => void): void;
    show?: boolean;
    handleSwitchClick?(): void;
    exchangeRate?: string | number;
    disableSwitch?: boolean;
}

function SwitchTokenInput({
    value,
    onChange,
    disabled,
    onInputValid,
    token,
    dToken,
    show = true,
    handleSwitchClick,
    exchangeRate,
    disableSwitch,
}: Props) {
    const { metis } = useConstantToken();
    // const balance  = useBalance(tokenAddress)
    const { balance, getBalance } = useTokenBalance(token.address);
    const [input, setInput] = useState('');

    useEffect(() => {
        if (!new BigNumber(input || 0).isEqualTo(value)) {
            setInput(value.toNumber() ? value.toFixed() : '');
        }
    }, [value, input]);

    const isMetis = useMemo(() => {
        return token.address.toLowerCase() === metis.address.toLowerCase();
    }, [token, metis]);

    const errorMessage = useMemo(() => {
        if (isMetis && value.isEqualTo(balance)) {
            return 'You should save some Metis for gas!';
        }
        if (value.isGreaterThan(balance)) {
            if (balance.eq(0)) {
                return 'Insufficient balance';
            } else {
                return `You must enter a quantity less than the ${balance.toFixed(
                    4,
                )}`;
            }
        } else {
            return '';
        }
    }, [value, balance, isMetis]);

    useEffect(() => {
        if (onInputValid) {
            onInputValid(!errorMessage);
        }
    }, [errorMessage, onInputValid]);

    useEffect(() => {
        if (value.eq(0)) {
            getBalance();
        }
    }, [value]);

    const hasBalance = useMemo(() => {
        return balance.isGreaterThan(0);
    }, [balance]);

    const hadDToken = useMemo(() => {
        return !!dToken;
    }, [dToken]);

    const estimateDTokenAmount = useMemo(() => {
        return value.multipliedBy(exchangeRate || 0).toFixed();
    }, [value, exchangeRate]);

    const isSwtichModel = useMemo(() => {
        return !!handleSwitchClick;
    }, [handleSwitchClick]);

    if (!show) return null;

    return (
        <AutoColumn gap="16px">
            <Wrapper>
                <InputRow align="between">
                    <div className="tokenWrapper">
                        <img className="token" src={token?.img || ''} />
                        <TYPE.main fontSize={16} fontWeight={600}>
                            {token.symbol}
                        </TYPE.main>
                    </div>
                    <StyledColumn
                        gap="16px"
                        style={{ flexGrow: 1 }}
                        justify="flex-end"
                    >
                        <input
                            type="number"
                            placeholder="0.00"
                            className={'input'}
                            value={input}
                            onChange={(e) => {
                                if (Number(e.currentTarget.value) < 0) {
                                    setInput('0');
                                    onChange(new BigNumber(0));
                                } else {
                                    setInput(e.currentTarget.value);
                                    onChange(
                                        new BigNumber(
                                            `${e.currentTarget.value}` || 0,
                                        ),
                                    );
                                }
                            }}
                            disabled={disabled}
                        />
                        <MaxButton
                            onClick={() => {
                                onChange(balance);
                            }}
                            loadingProps={{
                                classname: 'loading',
                                size: 16,
                            }}
                            showLoading
                            disabled={disabled || !hasBalance}
                        >
                            Max: {formatNumber(balance)}
                        </MaxButton>
                    </StyledColumn>
                </InputRow>
                {hadDToken && (
                    <>
                        <StyledLine>
                            <Line />
                            <StyledArow
                                className={!handleSwitchClick ? 'disabled' : ''}
                                src={
                                    isSwtichModel
                                        ? require('assets/images/ic_swicth.png')
                                        : require('assets/images/ic_single_switch.png')
                                }
                                onClick={() => {
                                    if (handleSwitchClick) {
                                        handleSwitchClick();
                                    }
                                }}
                            />
                            <Line />
                        </StyledLine>
                        <InputRow align="between">
                            <div className="tokenWrapper">
                                <img
                                    className="token"
                                    src={dToken?.img || ''}
                                />
                                <TYPE.main fontSize={18} fontWeight={600}>
                                    {dToken?.symbol}
                                </TYPE.main>
                            </div>
                            <input
                                type="number"
                                placeholder="0.00"
                                className={'input'}
                                value={estimateDTokenAmount}
                                disabled={disabled}
                            />
                        </InputRow>
                    </>
                )}

                {/* {!hideAddToken && (
                    <Row align="between">
                        <span></span>
                        <AddToken token={token} />
                    </Row>
                )} */}
            </Wrapper>

            {!disabled && errorMessage && (
                <Row>
                    <StyledWarning />
                    <TYPE.main fontSize={14} color="#DCA45C">
                        {errorMessage}
                    </TYPE.main>
                </Row>
            )}
        </AutoColumn>
    );
}

export default observer(SwitchTokenInput);
