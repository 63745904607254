import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const PageContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 25px;
    margin-top: 24px;
    position: relative;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    `};
`;

interface Props {
    children: React.ReactNode;
}
export default function PageContent(props: Props) {
    return <PageContentWrapper>{props.children}</PageContentWrapper>;
}

const LeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 26px;
    position: relative;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    `};
`;

export function LeftContent(props: Props) {
    return <LeftWrapper>{props.children}</LeftWrapper>;
}

const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
`;

export function ButtonGroup(props: Props) {
    return <ButtonWrapper>{props.children}</ButtonWrapper>;
}

const RightWrapper = styled.div`
    width: 240px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #150701;
    border-radius: 32px;

    .data-desc {
        color: #8c6437;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

export function RightContent(props: Props) {
    return <RightWrapper>{props.children}</RightWrapper>;
}

export const ButtonRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    button {
        width: 220px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: unset;
        `};
    }
`;

export function RightContentTitle(props: Props) {
    return (
        <TYPE.main
            color="#fff"
            fontSize={16}
            fontWeight={600}
            textAlign={'center'}
        >
            {props.children}
        </TYPE.main>
    );
}

const DetailWrapper = styled.div`
    background: #000000;
    border-radius: 32px;
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 10px;
    `};
`;

export function TransactionDetail(props: Props) {
    return <DetailWrapper>{props.children}</DetailWrapper>;
}

export function TransactionText(props: Props) {
    return (
        <TYPE.main
            fontSize={16}
            fontWeight={600}
            mobileFz={14}
            style={{ display: 'flex', alignItems: 'center' }}
        >
            {props.children}
        </TYPE.main>
    );
}

const StyledLogo = styled.img`
    width: 36px;
    height: 36px;
    margin-right: 16px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-right: 8px;
    `};
`;

export function TransactionTokenLogo({ src }: { src: string }) {
    return <StyledLogo src={src} alt="" />;
}

export const FullLineButton = styled(Button)`
    width: 464px !important;
`;

export const ComingSoonWrapper = styled.div`
    padding: 20px;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 32px;
    backdrop-filter: blur(2.4px);
    position: absolute;
    width: inherit;
    top: 0;
    bottom: -10px;
    z-index: 9999;
`;

export const ComingSoonImg = styled.img`
    width: 320px;
    height: 320px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ theme }) => theme.mediaWidth.upToSmall`
    top: 30%;
    `};
`;
