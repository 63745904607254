import { gql } from '@apollo/client';
import { getApolloClient } from './index';
import { Client } from './config';
import BigNumber from 'bignumber.js';

export function getPoints(user: string, time?: number): Promise<number> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                points: string;
            }>({
                query: gql`
                    query Query($user: String!, $time: Int) {
                        points(user: $user, time: $time)
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    user,
                    time,
                },
            })
            .then((res) => {
                if (res?.data?.points) {
                    resolve(
                        new BigNumber(res?.data?.points)
                            .shiftedBy(-18)
                            .toNumber(),
                    );
                    return;
                }
                resolve(0);
            })
            .catch((e: any) => {
                resolve(0);
            });
    });
}

export function getStakingPoints(user: string): Promise<number> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                stakingPoints: {
                    points: string;
                };
            }>({
                query: gql`
                    query Query($user: String!) {
                        stakingPoints(user: $user) {
                            points
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    user,
                },
            })
            .then((res) => {
                if (res?.data?.stakingPoints?.points) {
                    resolve(
                        new BigNumber(res?.data?.stakingPoints.points)
                            .shiftedBy(-18)
                            .toNumber(),
                    );
                    return;
                }
                resolve(0);
            })
            .catch((e: any) => {
                resolve(0);
            });
    });
}

export interface ReferralPoints {
    points: number;
    referrals: number;
    stakedByInvitees: number;
}

export const InitReferrals: ReferralPoints = {
    referrals: 0,
    stakedByInvitees: 0,
    points: 0,
};
export function getReferralsPoints(user: string): Promise<ReferralPoints> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                referrals: ReferralPoints;
            }>({
                query: gql`
                    query Query($user: String!) {
                        referrals(user: $user) {
                            points # referral points (decimals=18)
                            referrals # count of invited users
                            stakedByInvitees # total staked Metis by invitees (decimals=18)
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    user,
                },
            })
            .then((res) => {
                if (res?.data?.referrals) {
                    resolve({
                        points: new BigNumber(res.data.referrals?.points || 0)
                            .shiftedBy(-18)
                            .toNumber(),
                        referrals: res.data.referrals.referrals,
                        stakedByInvitees: new BigNumber(
                            res.data.referrals?.stakedByInvitees || 0,
                        )
                            .shiftedBy(-18)
                            .toNumber(),
                    });
                    return;
                }
                resolve(InitReferrals);
            })
            .catch((e: any) => {
                resolve(InitReferrals);
            });
    });
}

export function getGlobalPoints(): Promise<number> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                globalPoints: string;
            }>({
                query: gql`
                    query Query {
                        globalPoints
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                if (res?.data?.globalPoints) {
                    resolve(
                        new BigNumber(res?.data?.globalPoints)
                            .shiftedBy(-18)
                            .toNumber(),
                    );
                    return;
                }
                resolve(0);
            })
            .catch((e: any) => {
                resolve(0);
            });
    });
}

export function getGlobalStakingPoints(): Promise<number> {
    // if (!name) return Promise.resolve([]);
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                globalStakingPoints: {
                    points: string;
                };
            }>({
                query: gql`
                    query Query {
                        globalStakingPoints {
                            points
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                if (res?.data?.globalStakingPoints?.points) {
                    resolve(
                        new BigNumber(res?.data?.globalStakingPoints.points)
                            .shiftedBy(-18)
                            .toNumber(),
                    );
                    return;
                }
                resolve(0);
            })
            .catch((e: any) => {
                resolve(0);
            });
    });
}

export function getGlobalReferralsPoints(): Promise<ReferralPoints> {
    return new Promise((resolve, reject) => {
        getApolloClient(Client.graph)
            .query<{
                globalReferrals: ReferralPoints;
            }>({
                query: gql`
                    query Query {
                        globalReferrals {
                            points
                            referrals
                            stakedByInvitees
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                if (res?.data?.globalReferrals) {
                    resolve({
                        points: new BigNumber(
                            res.data.globalReferrals?.points || 0,
                        )
                            .shiftedBy(-18)
                            .toNumber(),
                        referrals: res.data.globalReferrals.referrals,
                        stakedByInvitees: new BigNumber(
                            res.data.globalReferrals?.stakedByInvitees || 0,
                        )
                            .shiftedBy(-18)
                            .toNumber(),
                    });
                    return;
                }
                resolve(InitReferrals);
            })
            .catch((e: any) => {
                resolve(InitReferrals);
            });
    });
}
