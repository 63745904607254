import { ChainId } from 'constant';
import { Distributor, getDistributors } from 'gql/distributor';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import storage from 'utils/storage';

const storageKey = 'enki-fantasy-distributors';
// const initRes = storage.get(storageKey) || {};

export default function useGetDistributor() {
    const [allDistributors, setAllDistributors] = useState<{
        [ChainId.mainnet]: Distributor[];
        [ChainId.testnet]: Distributor[];
        [ChainId.testnetSepolia]: Distributor[];
    }>();

    async function getDistributorFunc() {
        const res = await getDistributors();
        if (res) {
            const storageRes = storage.get(storageKey) || {};
            storageRes[`${user.chainId}`] = res;
            storage.set(storageKey, storageRes);
            setAllDistributors({
                ...storageRes,
            });
        } else {
            console.error('get empty distributors');
        }
    }

    useEffect(() => {
        getDistributorFunc();
    }, [user.chainId]);

    const distributors = useMemo(() => {
        return allDistributors ? allDistributors[user.chainId] : [];
    }, [user.chainId, allDistributors]);

    return {
        distributors,
    };
}
