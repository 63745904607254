import React from 'react';
import Modal from '../Modal';
import { observer } from 'mobx-react';
import Row, { RowBetween } from 'components/Row';
// import style from './index.module.scss';
import Button from 'components/Button';
import { Copy, ExternalLink } from 'react-feather';
import useCopyClipboard from 'hooks/useCopy';
import { formatHashValue } from 'utils';
import Alert from 'utils/components/alert';
import { NETWORK_CONFIG } from '../../constant';
import styled from 'styled-components';
import { TYPE } from 'theme';
import makeBlockie from 'ethereum-blockies-base64';
import { useAccount, useDisconnect } from 'wagmi';
import user from 'stores/user';
import { metisLogo } from 'common-helpers/img';

const Wrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    /* padding: 20px; */
`;

const StyledExternalLink = styled(ExternalLink)`
    color: #000;
`;

const StyledCopy = styled(Copy)`
    color: #000;
`;

const Content = styled.div`
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 24px;
    margin-top: 20px;

    .changeButton {
        padding: 6px;
        margin-left: 8px;
        border-radius: 4px;
        font-size: 14px;
    }

    .logo {
        width: 32px;
        height: 32px;
        margin-right: 10px;
        border-radius: 16px;
    }

    .bottom {
        margin-top: 20px;
    }

    .funcWrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 30px;

        .text {
            margin-left: 10px;
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
     .bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .funcWrapper {
            margin-bottom: 16px;
        }
     }
    `}
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
}
function AccountDetail({ isOpen, onClose }: Props) {
    const [, setCopied] = useCopyClipboard();
    const { connected, address: walletAddress } = user;
    const { disconnect } = useDisconnect();
    const { connector } = useAccount();

    async function handleChangeAccount() {
        await disconnect();
        user.clearLoginInfo();

        onClose();
        user.toggleConnectModal();
    }
    if (!connected) return null;
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            // customContentClass={style.accountContainer}
        >
            <Wrapper>
                <Row justify="center">
                    <TYPE.main fontSize={16} fontWeight="bold">
                        Account
                    </TYPE.main>
                </Row>
                <Content>
                    <RowBetween lineHeight="none">
                        <TYPE.main color={'#000'}>
                            Connected with {connector?.name || 'Unknown'}
                        </TYPE.main>
                        <Button
                            size="middle"
                            className={'changeButton'}
                            onClick={handleChangeAccount}
                        >
                            Change
                        </Button>
                    </RowBetween>
                    <Row>
                        <img
                            src={
                                walletAddress
                                    ? makeBlockie(walletAddress)
                                    : metisLogo
                            }
                            alt=""
                            className={'logo'}
                        />
                        <TYPE.main color={'#000'}>
                            {formatHashValue(walletAddress)}
                        </TYPE.main>
                    </Row>
                    <Row className="bottom">
                        <div
                            className={'funcWrapper'}
                            onClick={() => {
                                setCopied(walletAddress || '');
                                Alert({
                                    type: 'success',
                                    text: 'Copied!',
                                });
                            }}
                        >
                            <StyledCopy size={16} />
                            <TYPE.main color={'#000'} marginLeft="8px">
                                Copy Address
                            </TYPE.main>
                        </div>

                        <div
                            className={'funcWrapper'}
                            onClick={() => {
                                window.open(
                                    `${
                                        NETWORK_CONFIG[user.chainId]?.explore
                                    }address/${walletAddress}`,
                                );
                            }}
                        >
                            <StyledExternalLink size={16} />
                            <TYPE.main marginLeft="8px" color={'#000'}>
                                {NETWORK_CONFIG[user.chainId]?.scanLable}
                            </TYPE.main>
                        </div>
                    </Row>
                </Content>
            </Wrapper>
        </Modal>
    );
}

export default observer(AccountDetail);
