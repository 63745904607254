import { metisSepolia } from 'common-helpers/metisSepoliachain';
import { Chain, metis } from 'wagmi/chains';
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

export enum ChainId {
    testnet = 599,
    testnetSepolia = 59902,
    mainnet = 1088,
}

export const MainnetOnly = Number(process.env.REACT_APP_MAINNET_ONLY) === 1;

export const DefaultChainId = MainnetOnly
    ? ChainId.mainnet
    : ChainId.testnetSepolia;

export const SupportChainIDs = MainnetOnly
    ? [ChainId.mainnet]
    : [ChainId.mainnet, ChainId.testnetSepolia];

export const ProviderSupportChainId: Chain[] = [metisSepolia];

export const NETWORK_CONFIG = {
    [ChainId.testnet]: {
        name: 'Metis Goerli Testnet',
        scanLable: 'Metis(Goerli)-explore',
        explore: 'https://goerli.explorer.metisdevops.link',
    },
    [ChainId.testnetSepolia]: {
        name: 'Metis Sepolia Testnet',
        scanLable: 'Metis(Sepolia)-explore',
        explore: 'https://sepolia-explorer.metisdevops.link/',
    },
    [ChainId.mainnet]: {
        name: 'Andromeda',
        scanLable: 'Andromeda-explorer',
        explore: 'https://andromeda-explorer.metis.io/',
    },
};

export const SWITCH_NETWORK_CONFIG = {
    [ChainId.testnet]: {
        chainId: `0x${ChainId.testnet.toString(16)}`,
        chainName: 'Metis Goerli',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [
            'https://goerli.gateway.metisdevops.link',
            // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
        ],
        blockExplorerUrls: ['https://goerli.explorer.metisdevops.link'],
    },
    [ChainId.mainnet]: {
        chainId: `0x${ChainId.mainnet.toString(16)}`,
        chainName: 'Andromeda',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [`https://andromeda.metis.io/?owner=${ChainId.mainnet}`],
        blockExplorerUrls: ['https://andromeda-explorer.metis.io'],
    },
    [ChainId.testnetSepolia]: {
        chainId: `0x${ChainId.testnetSepolia.toString(16)}`,
        chainName: 'Metis Sepolia',
        nativeCurrency: {
            name: 'Metis Token',
            symbol: 'METIS',
            decimals: 18,
        },
        rpcUrls: [
            'https://sepolia.metisdevops.link',
            // `https://stardust.metis.io/?owner=${ChainId.layer2Testnet}`,
        ],
        blockExplorerUrls: ['https://sepolia-explorer.metisdevops.link'],
    },
};

export const RPC = {
    [ChainId.mainnet]: 'https://andromeda-rpc.metis.io',
    [ChainId.testnet]: 'https://goerli.gateway.metisdevops.link',
    [ChainId.testnetSepolia]: 'https://sepolia.metisdevops.link',
};

export type Token = {
    name: string;
    symbol: string;
    decimals: number;
    address: string;
    img?: string;
};

export enum FaucetToken {
    metis = 'metis',
    enki = 'enki',
}
