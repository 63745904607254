import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { RowBetween } from 'components/Row';
import Button from 'components/Page/StageButton';
import { AutoColumn } from 'components/Column';
import { enkiSymbol } from 'common-helpers/img';

import BigNumber from 'bignumber.js';

import { useConstantToken } from 'hooks/useConstantToken';
import useTokenApprove from 'hooks/useTokenApprove';
import useSend from 'hooks/useSend';
import user from 'stores/user';
import { observer } from 'mobx-react';
import useVestingMethods from 'hooks/useVestingMethods';
import useTokenBalance from 'hooks/useTokenBalance';
import { useGetEstimatedGas } from 'hooks/useEstimateGas';
import Numeral from 'numeral';
import useEnkiInfo from 'hooks/useEnkiInfo';
import useExploreLink from 'hooks/useExploreLink';

import PageContent, {
    ButtonGroup,
    ButtonRow,
    ComingSoonWrapper,
    LeftContent,
    RightContent,
    RightContentTitle,
    TransactionDetail,
    TransactionText,
    TransactionTokenLogo,
} from 'components/Page/PageContent';
import {
    ContentTitle,
    DataColumn,
    DataDesc,
    DataLink,
    DataTitle,
} from 'components/Page/DataComponents';
import PageWrapper from 'components/Page/PageWrapper';
import DataWrapper from 'components/Page/DataWrapper';
import SwitchTokenInput from 'components/TokenInput/SwitchTokenInput';
import { formatNumber } from 'utils';
import Modal from 'components/Modal';
import application from 'stores/application';
import { isMobile } from 'react-device-detect';
import useConfigContract from 'hooks/useConfigContract';

const AmountRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    padding: 0 24px;
`;

const Amount = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    img {
        width: 36px;
        height: 36px;
    }
`;

const ENKIRadio = 10;

function Vesting() {
    const { vestRatio } = useConfigContract();
    console.log(vestRatio);
    const { eMetis, enki } = useConstantToken();
    const { balance: eMetisBalance, getBalance: getEMetisBalance } =
        useTokenBalance(eMetis.address, user.address);

    const [inputEnki, setInputEnki] = useState(new BigNumber(0));
    const { getAddressUrl } = useExploreLink();

    const [inputENKIValid, setInputENKIValid] = useState(false);

    const {
        totalSupply: enkiTotalSupply,
        totalStaked: enkiTotalStaked,
        circulaingSupplay,
    } = useEnkiInfo();

    const {
        vestingContract,
        userStakeENKIBalance,
        claimableAmnount,
        cumulativeClaimAmount,
        getEstimateFinishedTime,
        getCanClaimAbleAmount,
        getUserInfo,
        leftDays,
        userLockEMetisBalance,
        claimedAmount,
    } = useVestingMethods();

    const send = useSend();

    const [isOpenENKITransModal, setIsOpenENKITransModal] = useState(false);

    const { approved: approvedVesting, approve: approveVesting } =
        useTokenApprove(enki, vestingContract?.address || '', inputEnki);

    function onCloseENKITransModal() {
        setIsOpenENKITransModal(false);
    }

    function onENKITransSuccess() {
        setIsOpenENKITransModal(false);
        setInputEnki(new BigNumber(0));
        getUserInfo();
        getCanClaimAbleAmount();
    }

    const estimatedGasStakeEnki = useGetEstimatedGas({
        contract: vestingContract,
        method: 'stake',
        params: [new BigNumber(1).shiftedBy(enki.decimals).toFixed(0)],
        show: inputEnki.isGreaterThan(0),
        fetch: approvedVesting && inputEnki.isGreaterThan(0),
    });

    async function stakeEnki() {
        if (!vestingContract) return;

        await send({
            contract: vestingContract,
            method: 'stake',
            params: [inputEnki.shiftedBy(enki.decimals).toFixed(0)],
            pendingText: `Staking ENKI`,
            summary: `Stake ${inputEnki.toFixed()} ENKI`,
            onSuccess: onENKITransSuccess,
        });
    }

    async function unstakeEnki() {
        if (!vestingContract) return;

        await send({
            contract: vestingContract,
            method: 'unstake',
            params: [],
            pendingText: `Withdrawing ENKI`,
            summary: `Withdraw ENKI`,
            onSuccess: onENKITransSuccess,
        });
    }

    const canClaimEmetisReward = useMemo(() => {
        return claimableAmnount.isGreaterThan(0);
    }, [claimableAmnount]);

    const unlockedEMetisReward = useMemo(() => {
        return claimableAmnount.plus(claimedAmount);
    }, [claimableAmnount, claimedAmount]);

    async function claimVestingEMetis() {
        if (!vestingContract || !canClaimEmetisReward) return;

        await send({
            contract: vestingContract,
            method: 'claim',
            params: [],
            onSuccess: () => {
                getCanClaimAbleAmount();
                getUserInfo();
                getEMetisBalance();
            },
        });
    }

    const canClickENKIStakeButton = useMemo(() => {
        return inputEnki.isGreaterThan(0) && inputENKIValid;
    }, [inputENKIValid, inputEnki]);

    const eMetisUnlockProgress = useMemo(() => {
        if (userLockEMetisBalance.isEqualTo(0)) return 0;
        if (claimedAmount.isGreaterThanOrEqualTo(userLockEMetisBalance))
            return 100;
        return claimedAmount
            .dividedBy(userLockEMetisBalance)
            .multipliedBy(100)
            .toFixed(2);
    }, [claimedAmount, userLockEMetisBalance]);

    useEffect(() => {
        if (application.preStaking) {
            application.setIsPreStakingModalOpen(true);
        }

        return () => {
            application.setIsPreStakingModalOpen(false);
        };
    }, [application.preStaking]);

    return (
        <PageWrapper>
            {application?.preStaking && <ComingSoonWrapper />}
            <DataWrapper>
                <DataColumn>
                    <DataTitle>
                        {Numeral(enkiTotalSupply).format('0,0')} ENKI
                    </DataTitle>
                    <DataDesc>ENKI Total Supply</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>
                        {Numeral(circulaingSupplay).format('0,0')} ENKI
                    </DataTitle>
                    <DataDesc>ENKI Circulating Supply</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>
                        {Numeral(enkiTotalStaked).format('0,0')} ENKI
                    </DataTitle>
                    <DataDesc>ENKI Staked</DataDesc>
                </DataColumn>
                <DataColumn leftBorder={!isMobile}>
                    <DataTitle>CONTRACTS</DataTitle>
                    <DataLink href={getAddressUrl(enki.address)}>
                        <DataDesc>ENKI</DataDesc>
                    </DataLink>
                    <DataLink
                        href={getAddressUrl(vestingContract?.address || '')}
                    >
                        <DataDesc>Vesting</DataDesc>
                    </DataLink>
                </DataColumn>
            </DataWrapper>
            <ContentTitle>Vesting</ContentTitle>
            {user.connected && (
                <TYPE.main fontSize={14} textAlign={'left'} marginTop={20}>
                    You have {formatNumber(userLockEMetisBalance)} eMetis in
                    vesting contract to be vested.&nbsp;
                    {userLockEMetisBalance.isGreaterThan(0)
                        ? `To vest in 90 days, suggested
                        staking mount is
                        ${formatNumber(
                            userLockEMetisBalance.multipliedBy(ENKIRadio),
                        )} ENKI.`
                        : ''}
                </TYPE.main>
            )}
            <PageContent>
                <LeftContent>
                    <SwitchTokenInput
                        value={inputEnki}
                        onChange={setInputEnki}
                        inputPercent={[50]}
                        token={enki}
                        onInputValid={setInputENKIValid}
                    />
                    <ButtonGroup>
                        <ButtonRow>
                            {!approvedVesting && user.connected && (
                                <Button
                                    preStaking
                                    auth
                                    onClick={approveVesting}
                                >
                                    Approve
                                </Button>
                            )}
                            <Button
                                preStaking
                                auth
                                disabled={
                                    !approvedVesting || !canClickENKIStakeButton
                                }
                                onClick={stakeEnki}
                            >
                                Stake
                            </Button>
                        </ButtonRow>
                    </ButtonGroup>
                    <TransactionDetail>
                        <RowBetween>
                            <TransactionText>CLAIMABLE</TransactionText>

                            <TransactionText>
                                <TransactionTokenLogo src={eMetis.img} />
                                {formatNumber(claimableAmnount)} eMetis
                            </TransactionText>
                        </RowBetween>
                    </TransactionDetail>
                    <ButtonGroup>
                        <ButtonRow>
                            <Button
                                disabled={!canClaimEmetisReward}
                                onClick={claimVestingEMetis}
                                type="outline"
                            >
                                Claim eMetis
                            </Button>
                        </ButtonRow>
                    </ButtonGroup>
                </LeftContent>
                <RightContent>
                    <AutoColumn gap="48px">
                        <RightContentTitle>Current Stake</RightContentTitle>
                        <AutoColumn gap="32px">
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(userStakeENKIBalance)} ENKI
                                </DataTitle>
                                <DataDesc>Your Staked Amount</DataDesc>
                                <Button
                                    type="outline"
                                    size="small"
                                    disabled={userStakeENKIBalance.isEqualTo(0)}
                                    onClick={() => {
                                        setIsOpenENKITransModal(true);
                                    }}
                                >
                                    Withdraw
                                </Button>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(eMetisBalance)} eMetis
                                </DataTitle>
                                <DataDesc>Your eMetis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(claimableAmnount)}/
                                    {formatNumber(userLockEMetisBalance)}
                                </DataTitle>
                                <DataDesc>Vesting Status</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>90 days</DataTitle>
                                <DataDesc>Vesting Duration</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {ENKIRadio} ENKI per eMetis
                                </DataTitle>
                                <DataDesc>Vesting Ratio</DataDesc>
                            </DataColumn>
                            <DataLink href="https://docs.enkixyz.com/guides/vesting">
                                <DataDesc>Documentation</DataDesc>
                            </DataLink>
                        </AutoColumn>
                    </AutoColumn>
                </RightContent>
            </PageContent>
            <Modal
                isOpen={isOpenENKITransModal}
                onClose={() => {
                    setIsOpenENKITransModal(false);
                }}
            >
                <AutoColumn justify="center" gap="20px">
                    <TYPE.main fontSize={20} fontWeight={600}>
                        Withdraw ENKI
                    </TYPE.main>
                    <AmountRow>
                        <TYPE.main color="#000">Amount</TYPE.main>
                        <Amount>
                            <img src={enkiSymbol} alt="" />
                            <TYPE.main color="#000">
                                {formatNumber(userStakeENKIBalance)}ENKI
                            </TYPE.main>
                        </Amount>
                    </AmountRow>
                    <TYPE.main fontSize={14}>
                        When you redeem the staked ENKI,your rewards will stop
                        releasing.
                    </TYPE.main>
                    <RowBetween>
                        <Button
                            type="outlineWhite"
                            onClick={() => {
                                setIsOpenENKITransModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                setIsOpenENKITransModal(false);
                                await unstakeEnki();
                            }}
                        >
                            Confirm
                        </Button>
                    </RowBetween>
                </AutoColumn>
            </Modal>
        </PageWrapper>
    );
}
export default observer(Vesting);
