import { metisSymbol, OuterLinkSVG, TipsSVG } from 'common-helpers/img';
import { ContentTitle } from 'components/Page/DataComponents';
import PageWrapper from 'components/Page/PageWrapper';
import Row from 'components/Row';
import TooltipInfo from 'components/TooltipInfo';
import { useGetDefiList } from 'hooks/useGetDefiList';
import React from 'react';
import styled from 'styled-components';
import { Link, TYPE } from 'theme';
import Tooltip from '@mui/material/Tooltip';
import { AutoColumn } from 'components/Column';
import { Skeleton } from '@mui/material';

const StyledTitleRow = styled(Row)`
    padding-top: 10px;
    gap: 12px;

    img {
        width: 36px;
        height: 36px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 4px;
    `};
`;

const DefiList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 50px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 32px;
    `};
`;

const RowTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &.title {
        padding: 0 40px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
        `};
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 20px;
    `};
`;

const ImgWrapper = styled.div`
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    img {
        width: 100%;
        height: #9b8c86;
    }
`;

const Td = styled.div`
    text-align: center;
    flex-grow: 0;
    flex-shrink: 0;
    width: 25%;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(50% - 10px);
    `};

    &.firstTd {
        display: flex;
        gap: 18px;
        align-items: center;
        justify-content: center;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        gap: 8px;
        `};
    }
`;

const DefiRowWrapper = styled.div`
    height: 120px;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.3) 100%
    );
    border-radius: 32px;
    backdrop-filter: blur(2.4px);
    padding: 32px 40px;
    cursor: pointer;
    position: relative;
    z-index: 0;
    width: 100%;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px;
    height: fit-content;
    `};
`;

const StyledOuterLinkIcon = styled(OuterLinkSVG)`
    color: ${({ theme }) => theme.c.mainColor};
    width: 12px;
    height: 12px;
`;

const StyledTipsSvg = styled(TipsSVG)`
    color: ${({ theme }) => theme.c.mainColor};
    width: 12px;
    height: 12px;
`;

const ComingSoon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.c.mainColor};
    z-index: 1;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 32px;

    backdrop-filter: blur(2.4px);
`;

const StyledSkeleton = styled(Skeleton)`
    background-color: #000;
    height: 120px;
    border-radius: 16px;
    transform: none;
    animation-duration: 1.5s;
`;

const TooltipsText = styled.div`
    font-size: 14px;
    a {
        color: ${({ theme }) => theme.c.mainColor};
    }
`;

export default function Defi() {
    const { defi, fetchFailed } = useGetDefiList();
    return (
        <PageWrapper>
            <ContentTitle>
                <Row gap="12px">
                    DeFi
                    <StyledTitleRow>
                        <TYPE.main
                            fontSize={24}
                            fontWeight={600}
                            color={'mainColor'}
                            mobileFz={16}
                        >
                            Networks
                        </TYPE.main>
                        <img src={metisSymbol} alt="" />
                        <TYPE.main
                            fontSize={24}
                            mobileFz={16}
                            fontWeight={600}
                            color={'mainColor'}
                        >
                            Metis
                        </TYPE.main>
                    </StyledTitleRow>
                </Row>
            </ContentTitle>
            <DefiList>
                <RowTitle className="title">
                    <Td className="firstTd">
                        <ImgWrapper />
                        <TYPE.main fontSize={18} color="mainColor">
                            PROTOCOL
                        </TYPE.main>
                    </Td>
                    <Td>
                        <TYPE.main fontSize={18} color="mainColor">
                            ASSETS
                        </TYPE.main>
                    </Td>
                    <Td>
                        <TYPE.main fontSize={18} color="mainColor">
                            BOOST RANGE
                        </TYPE.main>
                    </Td>
                    <Td>
                        <TYPE.main fontSize={18} color="mainColor">
                            STAKING POINTS
                        </TYPE.main>
                    </Td>
                </RowTitle>
                {!defi.length && (
                    <>
                        <StyledSkeleton width="100%" />
                        <StyledSkeleton width="100%" />
                        <StyledSkeleton width="100%" />
                    </>
                )}

                {defi.map((item, index) => (
                    <DefiRowWrapper
                        key={index}
                        onClick={() => {
                            if (item.isActive) {
                                window.open(item.link);
                            }
                        }}
                    >
                        <RowTitle>
                            <Td className="firstTd">
                                <ImgWrapper>
                                    <img src={item.logo} alt="" />
                                </ImgWrapper>
                                <AutoColumn justify="flex-start">
                                    <Link href={item.link}>
                                        <TYPE.main
                                            fontSize={18}
                                            fontWeight={600}
                                            color="mainColor"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '6px',
                                            }}
                                        >
                                            {item.protocol}
                                            <StyledOuterLinkIcon />
                                        </TYPE.main>
                                    </Link>
                                    <TYPE.main fontSize={14} color="mainColor">
                                        {item.chain}
                                    </TYPE.main>
                                </AutoColumn>
                            </Td>
                            <Td>
                                <TYPE.main
                                    fontSize={18}
                                    fontWeight={600}
                                    color="mainColor"
                                >
                                    {item.assets}
                                </TYPE.main>
                                <TYPE.main fontSize={14} color="mainColor">
                                    Pool/Token
                                </TYPE.main>
                            </Td>
                            <Td>
                                <TYPE.main
                                    fontSize={18}
                                    fontWeight={600}
                                    color="mainColor"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '6px',
                                    }}
                                >
                                    {item.boost}
                                    <Tooltip
                                        title={
                                            <TooltipsText
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.boostInfo,
                                                }}
                                            />
                                        }
                                        placement="top"
                                    >
                                        <StyledTipsSvg
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </Tooltip>
                                </TYPE.main>
                            </Td>
                            <Td>
                                <TYPE.main
                                    fontSize={18}
                                    fontWeight={600}
                                    color="mainColor"
                                >
                                    {item.stakingPoints}
                                </TYPE.main>
                            </Td>
                        </RowTitle>
                        {!item.isActive && <ComingSoon>COMING SOON</ComingSoon>}
                    </DefiRowWrapper>
                ))}
                {!defi.length && fetchFailed && (
                    <TYPE.main color={'red'} textAlign={'center'}>
                        Failed to fetch list data, please check the network and
                        try again
                    </TYPE.main>
                )}
            </DefiList>
        </PageWrapper>
    );
}
